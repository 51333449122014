import { computed, reactive } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { DynamicFormField } from '@oen.web.vue2/ui'

import {
  useFieldComponentHelper,
  useFieldPropsHelper,
  useFieldValidation,
} from '@galileo/composables/useFormHelpers'
import { useDynamicForm } from '@galileo/forms/useDynamicForm'

const createDynamicFormField = (field, defaultValues) => {
  let disabled = !!field.readonly
  let defaultValue = defaultValues && defaultValues[field.id] ? defaultValues[field.id] : ''

  const dynamicFormField = new DynamicFormField({
    field,
    value: defaultValue,
    hidden: false,
    disabled: disabled,
    props: useFieldPropsHelper(field),
    component: useFieldComponentHelper(field.type),
  })

  return reactive(dynamicFormField)
}

export const useBankAccountForm = () => {
  const { formValidation, formFields, hasFormFields, isFormInvalid } = useDynamicForm()

  const { createFormFieldValidation } = useFieldValidation()

  const setup = (fields, defaultValues = {}) => {
    const fieldData = []
    const validationData = {}
    const sortedFields = {
      accountType: null,
      localRoutingCode: null,
      accountNumber: null,
      accountAlias: null,
    }

    for (const field of fields) {
      if (field.id === 'accountAlias') {
        // TODO RETHINK
        continue
      }

      const formField = createDynamicFormField(field, defaultValues)
      validationData[formField.name] = createFormFieldValidation(formField)

      sortedFields[field.id] = formField
    }

    for (const key in sortedFields) {
      const field = sortedFields[key]
      if (field) {
        fieldData.push(field)
      }
    }

    formValidation.value = useValidation(validationData)

    for (const formField of fieldData) {
      formField.validation = formValidation.value[formField.name]
      if (formField.parent) {
        formField.parent = formField.parent = fieldData.find((item) => item.id === formField.parent)
      }
    }

    formFields.value = fieldData
  }

  return {
    setup,
    hasFormFields,
    isFormInvalid,
    formFields: computed(() => formFields.value),
    formValidation: computed(() => formValidation.value),
  }
}
