import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'
import RmtField from '@galileo/models/DynamicField/RmtField'
import { camelCase } from 'lodash-es'

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: 'account/bank/create',
    }
  },
  (response) => {
    const fields = {
      billingFields: null,
      bankFields: null,
    }

    const {
      data: {
        formData: {
          BillingAddressForm: { formFields: billingFields },
          CreateBankAccountForm: { formFields: bankFields },
        },
      },
    } = response

    fields.billingFields = billingFields.map((field) => {
      return new RmtField(field)
    })

    fields.bankFields = bankFields.map((field) => {
      return new RmtField(field)
    })

    return {
      ...response,
      billingFields: fields.billingFields,
      bankFields: fields.bankFields,
    }
  }
)
