<template>
  <AppModal v-model="model" :title="$t('ComponentBankAccountModal.TitleModal').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #left>
          <AppBackButton
            name="Go Back"
            icon="<"
            analytics-name="bank-account-modal-back"
            @click="dismiss"
          />
        </template>

        <h1>{{ $t('ComponentBankAccountModal.Header').value }}</h1>

        <template #right>
          <AppBackButton
            analytics-name="bank-account-modal-back-right"
            @click="$router.toParentRoute()"
          />
        </template>
      </AppModalHeader>
    </template>

    <AppSpinnerBig v-if="loadingFields" class="mb-10" inline :loading="loadingFields" />

    <AppDynamicForm :fields="formFields"></AppDynamicForm>

    <template #footer>
      <AppModalFooter v-if="hasFormFields">
        <AppButton
          analytics-name="bank-account-modal-continue"
          :disabled="isFormInvalid"
          :loading="loadingAddBank"
          @click="submit"
          >{{ $t('ComponentBankAccountModal.ButtonContinue').value }}</AppButton
        >
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { reactive, computed, onBeforeMount } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import getBankFields from '@galileo/api/rmt/account/bank/create/get'
import { useBankAccountForm } from '@galileo/forms/BankAccountForm'
import { useRouter } from '@galileo/composables/useRouter'

import { useI18nStore, useAppStore, useAuthStore, useBankAccountStore } from '@galileo/stores'

import {
  useMediaQuery,
  useVModel,
  AppModal,
  AppDynamicForm,
  AppSpinnerBig,
  AppModalFooter,
  AppBackButton,
  AppButton,
  AppModalHeader,
} from '@oen.web.vue2/ui'

export default {
  name: 'BankAccountModal',
  components: {
    AppModalHeader,
    AppButton,
    AppModal,
    AppDynamicForm,
    AppSpinnerBig,
    AppModalFooter,
    AppBackButton,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const bankAccountStore = useBankAccountStore()

    const router = useRouter()
    const authStore = useAuthStore()
    const appStore = useAppStore()

    const { setup, formFields, hasFormFields, isFormInvalid } = useBankAccountForm(null)

    const { loading: loadingFields, exec: getFields } = usePromiseLazy(() => getBankFields.exec())

    onBeforeMount(async () => {
      const { bankFields } = await getFields()
      const defaultValues = {}

      setup(bankFields, defaultValues)
    })

    const userProfile = authStore.userProfile

    const billingAddress = reactive({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      country: userProfile.country,
      street: userProfile.street,
      city: userProfile.city,
      state: userProfile.state,
      postalCode: userProfile.postalCode,
    })

    const getFieldValue = (fields, fieldId) => {
      return fields.value.filter((field) => field.id === fieldId)[0].value
    }

    const accountData = reactive({
      accountType: computed(() => {
        return getFieldValue(formFields, 'accountType')
      }),
      localRoutingCode: computed(() => {
        return getFieldValue(formFields, 'localRoutingCode')
      }),
      accountNumber: computed(() => {
        return getFieldValue(formFields, 'accountNumber')
      }),
      firstName: billingAddress.firstName,
      billingAddress: billingAddress.street,
      billingCity: billingAddress.city,
      billingState: billingAddress.state,
      billingPostalCode: billingAddress.postalCode,
      billingCountry: billingAddress.country,
    })

    const {
      exec: execAddBank,
      error: errorAddBank,
      result: resultAddBank,
      loading: loadingAddBank,
    } = usePromiseLazy(() => bankAccountStore.addBankAccount(accountData))

    const submit = async () => {
      await execAddBank()
      if (errorAddBank.value) {
        const errorObj = {
          title: $t('ComponentBankAccountModal.ErrorAddTitle').value,
          text: errorAddBank.value.friendlyMessage
            ? errorAddBank.value.friendlyMessage
            : $t('ComponentBankAccountModal.ErrorAddText').value,
        }
        appStore.messageBoxGenericOk(errorObj.title, errorObj.text)
      }

      if (resultAddBank.value) {
        router.replace('add-bank-verification')
      }
    }

    return {
      model,
      submit,
      mq: reactive(useMediaQuery()),
      formFields,
      hasFormFields,
      isFormInvalid,
      loadingFields,
      loadingAddBank,
      accountData,
      $t,
    }
  },
}
</script>

<style scoped>
::v-deep .input:last-of-type {
  /* a temporary fix for the AppDynamicForm component when used along the AppModalFooter -
      the last input element should not have margin bottom  */
  @apply mb-0;
}
</style>
